// Action Types
import * as Actions from "./action-types";
import Logger from '../../Utils/Logger';
// Lodash
import _ from 'lodash';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Error Handler
import errorHandler from "../../Utils/errorHandler";
// Constants
import { PAGES} from "../../Utils/CommonConstants";
// Parser
import {adjustCartWithSizeScaleRevamped, adjustMaxCartDeliveryDate, getUniqueOrders} from './parser';
// Helpers
import {customDateFormatter, getLatestDeliveryDate, getTrackerDO} from "../../Utils/Helpers";
import {_setAllSizes} from '../Order/action';
import {extractSizeForSplitItem} from '../Order/parser';
import {logTrack} from '../Tracker/actions';
import {SET_CHILD_OPEN_FOR_EMBROIDERY} from "./action-types";

export const addToCart = (id, product, size, sizeNumber, option = 1, inputQuantity, split = false) => { // Option: 1 => Increment, 2 => Decrement, 3 => Input
    Logger.log("====== Adding to cart ======");
    Logger.log("=== ID: ", id);
    Logger.log("=== OPTION: ", option);
    Logger.log("=== QTY: ", inputQuantity);
    Logger.log("=== SIZE: ", size);
    Logger.log("=== SIZE NUMBER: ", sizeNumber);
    Logger.log("=== SPLIT : ", split);
    Logger.log("=== Product : ", product);
    return (dispatch, getStore) => {
        dispatch(_setProcessing(true));

        const cartDetailRevamped = getStore().cart.cartDetailRevamped;
        const maxCartDeliveryDate = getStore().cart.maxCartDeliveryDate;
        const userData = getStore().user.userData;
        const trackerData = getStore().tracker.tracker;

        const cartDetailRevampedParsed = adjustCartWithSizeScaleRevamped(cartDetailRevamped, product, size, sizeNumber, id, option, inputQuantity, split);
        dispatch(_setCartDetailRevamped(cartDetailRevampedParsed));

        const findCurrentItem = _.find(cartDetailRevampedParsed, item => item.id === product.id);
        if(findCurrentItem) {
            const latestDeliveryDate = getLatestDeliveryDate(findCurrentItem.qty, findCurrentItem.itemAvailability);
            if(latestDeliveryDate) {
                findCurrentItem.changeableDeliveryDate = latestDeliveryDate;
            }
        }



        dispatch(_setMaxCartDeliveryDate(adjustMaxCartDeliveryDate(cartDetailRevampedParsed, maxCartDeliveryDate)));
        dispatch(_setMinMaxCartDeliveryDate(adjustMaxCartDeliveryDate(cartDetailRevampedParsed, maxCartDeliveryDate)));

        dispatch(_setUserId(userData.id));

        dispatch(_setUniqueCartItems(getUniqueOrders(cartDetailRevampedParsed)));
        dispatch(_setProcessing(false));

        dispatch(_toggleAsyncStorage(true));
        dispatch(logTrack(getTrackerDO(trackerData, "ITEM ADDED TO CART", PAGES.CART, product)));
    }

}
export const modifyTheSplitItem = (item, size, sizeNumber, option = 1, inputQuantity, cb) => { // Option: 1 => Increment, 2 => Decrement, 3 => Input
    Logger.log("====== Adding to cart ======");
    Logger.log("=== ITEM: ", item);
    Logger.log("=== OPTION: ", option);
    Logger.log("=== QTY: ", inputQuantity);
    Logger.log("=== SIZE: ", size);
    Logger.log("=== SIZE NUMBER: ", sizeNumber);

    return (dispatch, getStore) => {
        const cartParsed = adjustCartWithSizeScaleRevamped([item], item, size, sizeNumber, item.id + "split", option, inputQuantity, false);
        dispatch(_setItemToBeSplit(cartParsed[0]));
        const trackerData = getStore().tracker.tracker;

        dispatch(logTrack(getTrackerDO(trackerData, "ITEM MODIFIED IN SPLIT ITEM", PAGES.CART, cartParsed)));
    }
}


// Change Delivery Date In Cart Parsed
export const changeDeliveryDateInCart = (deliveryDate, productId, updateUserDefinedDate = false, cb) => {
    return (dispatch, getStore) => {
        dispatch(_setProcessing(true));

        const cartState = getStore().cart;
        const trackerData = getStore().tracker.tracker;


        const cartDetailParsed = [...cartState.cartDetailRevamped];

        const check = _.find(cartDetailParsed, p => p.id === productId);

        if (check) {
            Logger.log("DATED CHANGED: ", deliveryDate);
            Logger.log("DATED CHANGER FORMATTER: ", customDateFormatter(deliveryDate));
            const deliveryDateDuplicate = check.changeableDeliveryDate;
            check.changeableDeliveryDate = customDateFormatter(deliveryDate);
            // check.changeableDeliveryDate.luxon = deliveryDate;
            if(updateUserDefinedDate) {
                check.userDefinedDate = customDateFormatter(deliveryDate);
            }

        }

        dispatch(_setMaxCartDeliveryDate(adjustMaxCartDeliveryDate(cartDetailParsed)));
        dispatch(_setCartDetailParsed(cartDetailParsed));
        dispatch(_setCartDetailRevamped(cartDetailParsed));
        dispatch(_setUniqueCartItems(getUniqueOrders(cartDetailParsed)));
        dispatch(_setProcessing(false));

        dispatch(logTrack(getTrackerDO(trackerData, "DELIVERY DATE CHANGED", PAGES.CART, check)));
    };
}
export const deleteItemFromCart = (item, cb) => {

    return (dispatch, getStore) => {
        dispatch(_setProcessing(true));

        const trackerData = getStore().tracker.tracker;

        const cartDetails = [...getStore().cart.cartDetailRevamped];

        const itemChecker = _.find(cartDetails, p => p.id === item.id);
        Logger.log("Item to Delete from cart", itemChecker);

        _.remove(cartDetails, p => p.id === itemChecker.id);

        dispatch(_setUniqueCartItems(getUniqueOrders(cartDetails)));
        Logger.log("Cart Detail", cartDetails);
        dispatch(_setCartDetailRevamped(cartDetails));
        dispatch(_setMaxCartDeliveryDate(adjustMaxCartDeliveryDate(cartDetails)));

        dispatch(_toggleAsyncStorage(true));
        dispatch(_setProcessing(false));


        dispatch(logTrack(getTrackerDO(trackerData, "ITEM DELETED FROM CART", PAGES.CART, itemChecker)));
    };


}

export const setMaxDeliveryDateForAllItems = (cartDetail, date, cb) => {
    return (dispatch, getStore) => {
        dispatch(_setProcessing(true));

        const cartDetailCopy = [...cartDetail];

        const trackerData = getStore().tracker.tracker;

        _.map(cartDetailCopy, (cart, index) => {
            cart.changeableDeliveryDate = customDateFormatter(date.toISODate());
        });
        dispatch(_setUniqueCartItems(getUniqueOrders(cartDetailCopy)));
        dispatch(_setCartDetailRevamped(cartDetailCopy));
        dispatch(_setProcessing(false));

        dispatch(logTrack(getTrackerDO(trackerData, "ASSIGNED MAX DELIVERY DATE TO ALL CART", PAGES.CART, {maxDate: date, cart:cartDetail})));

        cb && cb();
    };
}

export const saveFromCartStorage = (data, tempCart, cb) => {

    return (dispatch, getStore) => {
        if (data.hasOwnProperty("cart") && data.hasOwnProperty("customer") && data.hasOwnProperty("store")) {

            const trackerData = getStore().tracker.tracker;
            const {cart, customer, store} = data;
            dispatch(_setCartDetailRevamped([]));
            dispatch(_setCartDetailRevamped(cart));
            if(!tempCart) {
                dispatch(_setSelectedCustomer(customer));
                dispatch(_setSelectedStore(store));
            }
            dispatch(_setUniqueCartItems(getUniqueOrders(cart)));
            dispatch(_toggleAsyncStorage(true));

            dispatch(logTrack(getTrackerDO(trackerData, "SAVED CART FROM STORAGE", PAGES.CART, data)));


            cb && cb();
        } else {
            cb && cb(true);
        }
    };
}

export const saveCartStorage = (data, cb) => {
    return (dispatch) => {

        dispatch(_setProcessing(true));
        axios.post(URLS.CartStorageUrls.SaveCartStorageByUser, data)
            .then(response => {

                Logger.log("Saving Cart Storage | Response: ", response.data);

                dispatch(_setCartDetailRevamped([]));
                dispatch(_setSelectedCustomer(null));
                dispatch(_setSelectedStore(null));
                dispatch(_setUniqueCartItems([]));
                dispatch(_setProcessing(false));

                dispatch(_toggleAsyncStorage(true));

                cb && cb();
            })
            .catch(error => {
                Logger.error("Saving Cart Storage | Error : ", error);
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })

    }
}

export const getAllCartStorageByUserId = (userId, cb) => {
    return (dispatch) => {

        dispatch(_setProcessing(true));
        axios.get(URLS.CartStorageUrls.GetAllByUser + userId)
            .then(response => {

                Logger.log("Get All Cart Storage | Response: ", response.data);

                dispatch(_setCartStorage(response.data.content));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("Get All Cart Storage | Error : ", error);
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })

    }


}

export const getAllCartStorageByRoleId = (cb) => {
    return (dispatch) => {

        dispatch(_setProcessing(true));
        axios.get(URLS.CartStorageUrls.GetAllByRoleId + window.config.TEMP_ACCOUNT_ROLE_ID)
            .then(response => {

                Logger.log("Get All Temp Cart Storage | Response: ", response.data);

                dispatch(_setTempAccountCartStorage(response.data.content));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("Get All Temp Cart Storage | Error : ", error);
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })

    }


}

export const deleteCartStorageById = (cartId, cb) => {
    return (dispatch) => {

        dispatch(_setProcessing(true));
        axios.delete(URLS.CartStorageUrls.DeleteCartStorageByUser + cartId)
            .then(response => {

                Logger.log("Delete Cart Storage | Response: ", response.data);

                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("Delete All Cart Storage | Error : ", error);
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })

    }


}

export const splitItem = (item, cb) => {
    return (dispatch,getStore) => {
        const trackerData = getStore().tracker.tracker;
        dispatch(_setProcessing(true));
        dispatch(_setItemToBeSplit(item));
        dispatch(_setAllSizes(extractSizeForSplitItem(item)));
        dispatch(_setProcessing(false));

        dispatch(logTrack(getTrackerDO(trackerData, "ITEM SPLIT", PAGES.CART, item)));
        cb && cb(item);

    };
}
export const setEditItem = (item, cb) => {
    return (dispatch, getStore) => {
        const trackerData = getStore().tracker.tracker;
        dispatch(_setProcessing(true));
        dispatch(_setItemToBeEdit(item));
        dispatch(_setAllSizes(extractSizeForSplitItem(item)));
        dispatch(_setProcessing(false));
        dispatch(logTrack(getTrackerDO(trackerData, "ITEM EDIT", PAGES.CART, item)));

        cb && cb(item);

    };
}

export const _clearCart = (cb) => {

    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_resetState());
        dispatch(_toggleAsyncStorage(false));
        dispatch(_setProcessing(false));


        cb && cb();
    }
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};

export const _setSelectedCustomer = (payload) => {
    return {
        type: Actions.SET_SELECTED_CUSTOMER,
        payload,
    };
};
export const _setSelectedStore = (payload) => {
    return {
        type: Actions.SET_SELECTED_STORE,
        payload,
    };
};
// const _setError = (payload) => {
//     return {
//         type: Actions.SET_ERROR,
//         payload,
//     };
// };
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
// const _setCartDetail = (payload) => {
//     return {
//         type: Actions.SET_CART_DETAIL,
//         payload,
//     };
// };

const _setCartDetailParsed = (payload) => {
    return {
        type: Actions.SET_CART_DETAIL_PARSED,
        payload,
    };
};
const _setUserId = (payload) => {
    return {
        type: Actions.SET_USER_ID,
        payload,
    };
};
export const _toggleAsyncStorage = (payload) => {
    return {
        type: Actions.TOGGLE_STORAGE,
        payload,
    };
};
export const _resetState = (payload) => {
    return {
        type: Actions.RESET_STATE,
        payload
    }
}

export const _setOpen = (payload) => {
    return {
        type: Actions.SET_OPEN,
        payload
    }
}

export const _setChildOpen = (payload) => {
    return {
        type: Actions.SET_CHILD_OPEN,
        payload
    }
}
export const _setChildOpenForEdit = (payload) => {
    return {
        type: Actions.SET_CHILD_OPEN_FOR_EDIT,
        payload
    }
}
export const _setChildOpenForEmbroidery = (payload) => {
    return {
        type: Actions.SET_CHILD_OPEN_FOR_EMBROIDERY,
        payload
    }
}
export const _setItemToBeEdit = (payload) => {
    return {
        type: Actions.SET_ITEM_TO_BE_EDIT,
        payload
    }
}
export const _setItemToBeSplit = (payload) => {
    return {
        type: Actions.SET_ITEM_TO_BE_SPLIT,
        payload
    }
}

export const _setCartDetailRevamped = (payload) => {
    return {
        type: Actions.SET_CART_DETAIL_REVAMPED,
        payload
    }
}

export const _setCartStorage = (payload) => {
    return {
        type: Actions.SET_CART_STORAGE,
        payload
    }
}

export const _setTempAccountCartStorage = (payload) => {
    return {
        type: Actions.SET_TEMP_ACCOUNT_CART_STORAGE,
        payload
    }
}
export const _setMaxCartDeliveryDate = (payload) => {
    return {
        type: Actions.SET_MAX_CART_DELIVERY_DATE,
        payload
    }
}
export const _setMinMaxCartDeliveryDate = (payload) => {
    return {
        type: Actions.SET_MIN_MAX_CART_DELIVERY_DATE,
        payload
    }
}
export const _setUniqueCartItems = (payload) => {
    return {
        type: Actions.SET_UNIQUE_CART_ITEMS,
        payload
    }
}
