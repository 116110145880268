// Library
import _ from 'lodash';
// Logger
import Logger from "../../Utils/Logger";
// Constants
import {SIZES_NAME} from '../../Utils/CommonConstants';
// Helper
import {areAllQuantitiesZero, getLatestDeliveryDate, toaster} from '../../Utils/Helpers';


export const adjustCartWithSizeScaleRevamped = (cartRevamped, item, size, sizeNumber, id, option, inputQuantity, split) => {

    const errorMessage = "No inventory available at this moment."
    const cart = [];
    Logger.log("=================================================================");
    Logger.log("== Adjusting Cart Detail With Size Revamped");
    Logger.log("== Cart Revamped :", cartRevamped);
    Logger.log("== Item :", item);
    Logger.log("== Size :", size);
    Logger.log("== Size Number:", sizeNumber);
    Logger.log("== ID :", id);
    Logger.log("== Option :", option);
    Logger.log("== Input QTY :", inputQuantity);

    let maxQtySelection = 0;
    let deliveryDate = item.deliveryDate;

    const {itemAvailability} = item;
    const currentSizeStatus = _.find(itemAvailability, p => p.sizeNumber === sizeNumber);

    if (!split) {
        maxQtySelection = currentSizeStatus.maxQtySelection;
    }

    deliveryDate = currentSizeStatus.deliveryDate;
    Logger.log("== Available Status :", itemAvailability);
    Logger.log("== Available Status For Size:", currentSizeStatus);
    Logger.log("== Max Quantity For Selection : ", maxQtySelection);
    Logger.log("== Delivery Date : ", deliveryDate);
    Logger.log("== Grace Period for Delivery : ", window.config.GRACE_TIME_DELIVERY_PERIOD);


    if(currentSizeStatus.hasOwnProperty("alternativeQtyAndDeliveryDate")
    && currentSizeStatus.alternativeQtyAndDeliveryDate) {
        const {alternativeQtyAndDeliveryDate} = currentSizeStatus;
        Logger.log("== Alternative QTY & Delivery Date Found : ", alternativeQtyAndDeliveryDate);
        maxQtySelection = maxQtySelection + alternativeQtyAndDeliveryDate.wipQty;
    }

    let cartToIncreaseOrDecrease = 1;
    Logger.log("== QTY to be added :", cartToIncreaseOrDecrease);
    let replaceQty = false;

    if (option === 3) {
        replaceQty = true;
        cartToIncreaseOrDecrease = parseInt(inputQuantity);
        Logger.log("== Input QTY is placed | So Cart QTY to be added: ", cartToIncreaseOrDecrease);
    } else if (option === 2) {
        cartToIncreaseOrDecrease = -1;
        Logger.log("== Cart Is Decreasing : ", cartToIncreaseOrDecrease);
    }
    Logger.log("=================================================================");

    if (cartRevamped.length) {
        Logger.log("== Cart is already present");
        Logger.log("=== Checking for the Item : ", item.id);
        const checkForAlreadyPresent = _.find(cartRevamped, p => p.id === item.id);
        Logger.log("=== Checking for Split Item");
        if (split && checkForAlreadyPresent) {
            Logger.log("=== The item is already present in the cart & going to split now : ", checkForAlreadyPresent);
            const splitItem = {...item};
            splitItem.id = item.id + "_split";
            cartRevamped.push(splitItem);
        } else if (checkForAlreadyPresent) {
            Logger.log("=== The item is already present in the cart : ", checkForAlreadyPresent);
            const qtyAlreadyPresent = checkForAlreadyPresent.qty[size];
            Logger.log("=== Already Present QTY: ", qtyAlreadyPresent);
            let newQty = replaceQty ? cartToIncreaseOrDecrease :  parseInt(qtyAlreadyPresent) + cartToIncreaseOrDecrease;
            Logger.log("=== New QTY: ", newQty);
            Logger.log("=== Checking For the delivery Date ");

            if (!Number.isFinite(maxQtySelection)) {
                Logger.log("=== The Item have infinite number of inventory, Ignoring the max qty check now..");
                if (option === 2 && newQty < 0) {
                    checkForAlreadyPresent.qty[size] = 0;
                } else {
                    if (option === 1 || option === 2) {
                        checkForAlreadyPresent.qty[size] = newQty;
                    } else if (option === 3) {
                        checkForAlreadyPresent.qty[size] = cartToIncreaseOrDecrease;
                    }
                }
                checkForAlreadyPresent.qty["totalQuantity"] = getSumOfItems(checkForAlreadyPresent);
            } else {
                Logger.log("=== The Item have finite number of inventory, Checking the max qty check now..");
                if (newQty > maxQtySelection) {
                    toaster(errorMessage);
                } else {
                    if (option === 2 && newQty < 0) {
                        checkForAlreadyPresent.qty[size] = 0;
                    } else {
                        if (option === 1 || option === 2) {
                            checkForAlreadyPresent.qty[size] = newQty;
                        } else if (option === 3) {
                            checkForAlreadyPresent.qty[size] = cartToIncreaseOrDecrease;
                        }
                    }
                    checkForAlreadyPresent.qty["totalQuantity"] = getSumOfItems(checkForAlreadyPresent);
                }
            }

            if(cartToIncreaseOrDecrease <= 0 && areAllQuantitiesZero(checkForAlreadyPresent)) {
                _.remove(cartRevamped, p => p.id === checkForAlreadyPresent.id);
                return cartRevamped;
            }

        } else {
            Logger.log("=== The item is not present in the cart : ", item);

            if (!Number.isFinite(maxQtySelection)) {
                Logger.log("=== The Item have infinite number of inventory, Ignoring the max qty check now..");
                if (option === 1 || option === 3) {
                    cartRevamped.push({
                        ...item,
                        changeableDeliveryDate: deliveryDate,
                        deliveryDate: item.deliveryDate,
                        qty: getCartQty(size, cartToIncreaseOrDecrease),
                        embroideryDetails: []
                    });
                }
            } else {
                Logger.log("=== The Item have finite number of inventory, Checking the max qty check now..");
                if (cartToIncreaseOrDecrease > maxQtySelection) {
                    toaster(errorMessage);
                } else {
                    if (option === 1 || option === 3) {
                        cartRevamped.push({
                            ...item,
                            changeableDeliveryDate: deliveryDate,
                            deliveryDate: item.deliveryDate,
                            qty: getCartQty(size, cartToIncreaseOrDecrease),
                            embroideryDetails: []
                        });
                    }
                }
            }
        }

        Logger.log("=== Cart Detail Revamped Updated: ", cartRevamped);

        return cartRevamped;


    } else {
        Logger.log("== Cart is not present, Going to create a new Cart");
        if (option === 1 || option === 3) {
            if (item.deliveryDateInGracePeriod) {
                Logger.log("=== The Item delivery date is greater than Grace Period, Ignoring the max qty check now..");
                cart.push({
                    ...item,
                    changeableDeliveryDate: deliveryDate,
                    changeableDeliveryDateDuplicate: deliveryDate,
                    deliveryDate: item.deliveryDate,
                    qty: getCartQty(size, cartToIncreaseOrDecrease),
                    embroideryDetails: [],
                    shouldAddEmbroidery: false
                });
            } else {
                Logger.log("=== The Item delivery date is less than Grace Period, Checking the max qty check now..");
                if (cartToIncreaseOrDecrease > maxQtySelection) {
                    toaster(errorMessage);
                } else {
                    cart.push({
                        ...item,
                        changeableDeliveryDate: deliveryDate,
                        changeableDeliveryDateDuplicate: deliveryDate,
                        deliveryDate: item.deliveryDate,
                        qty: getCartQty(size, cartToIncreaseOrDecrease),
                        embroideryDetails: [],
                        shouldAddEmbroidery: false
                    });
                }
            }
        }
    }

    return cart;
}
export const adjustMaxCartDeliveryDate = (cartDetailRevamped, maxDeliveryDate) => {
    _.map(cartDetailRevamped, (cart, index) => {

        if (cart.changeableDeliveryDate.luxon > maxDeliveryDate) {
            maxDeliveryDate = cart.changeableDeliveryDate.luxon;
        }
    });


    return maxDeliveryDate;

}

const getCartQty = (size, qty) => {

    const returnerQty = {};

    _.map(SIZES_NAME, (s, i) => {
        returnerQty[s] = size === s ? qty : 0;
    })
    returnerQty.other =  _.includes(SIZES_NAME, size) ? 0 : 1;
    returnerQty.totalQuantity =  qty;
    return returnerQty;

    // return {
    //     XXS: size === "XXS" ? qty : 0,
    //     XS: size === "XS" ? qty : 0,
    //     S: size === "S" ? qty : 0,
    //     M: size === "M" ? qty : 0,
    //     L: size === "L" ? qty : 0,
    //     XL: size === "XL" ? qty : 0,
    //     XXL: size === "XXL" ? qty : 0,
    //     "ONE SIZE": size === "ONE SIZE" ? qty : 0,
    //     "KIDS 9-3": size === "KIDS 9-3" ? qty : 0,
    //     "WOMEN 4-10": size === "WOMEN 4-10" ? qty : 0,
    //     other: _.includes(SIZES_NAME, size) ? 0 : 1,
    //     totalQuantity: qty
    // }
}
const getSumOfItems = (item) => {
    Logger.log("=== SUMMING UP THE QTY: ", item);

    const qty = [];
    _.map(SIZES_NAME, (s, i) => {
        qty.push(item.qty[s]);
    })

    return _.sum(qty);

    // return _.sum(
    //     [
    //         item.qty.XXS,
    //         item.qty.XS,
    //         item.qty.S,
    //         item.qty.M,
    //         item.qty.L,
    //         item.qty.XL,
    //         item.qty.XXL,
    //         item.qty["ONE SIZE"],
    //         item.qty["KIDS 9-3"],
    //         item.qty["WOMEN 4-10"],
    //         item.qty.other,
    //     ]
    // );
}

export const getUniqueOrders = (orders) => {
    const groupedOrders = orders.reduce((acc, item) => {
        const key = `${item.changeableDeliveryDate.formatted}-${item.officialDivision}`;
        if (!acc[key]) {
            acc[key] = { deliveryDate: item.changeableDeliveryDate.formatted, division: item.officialDivision, items: [] , key: key};
        }
        acc[key].items.push(item);
        return acc;
    }, {});
    Logger.log("=== Unique Orders", groupedOrders);

    const groupedOrdersArray=  Object.values(groupedOrders).map(group => ({
        deliveryDate: group.deliveryDate,
        division: group.division,
        items: group.items
    }));

    Logger.log("=== Unique Orders Array", groupedOrdersArray);

    return groupedOrdersArray;
}
