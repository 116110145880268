import Logger from "./Logger";
import {toast} from "react-toastify";
import {MONTHS} from "./CommonConstants";
import _, {size} from 'lodash';
import Swal from "sweetalert2";
import {DateTime} from "luxon";
import CryptoJS from 'crypto-js';
import logger from "./Logger";

export const toAbsoluteUrl = (pathname) => {
    return getPublicUrl() + pathname;
}
export const getPublicUrl = () => {
    return process.env.PUBLIC_URL;
}
export const singleCartIdGenerator = (size, productId, userId, isSplit = false) => {
    let cartUniqueId = "s_" + size + "_p_" + productId + "_u_" + userId;

    if (isSplit) {
        cartUniqueId = cartUniqueId + "splt";
    }

    return cartUniqueId;
}

export const toaster = (message, severity = "danger", progress = undefined, autoClose = 5000, position = "top-center") => {
    const options = {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: progress,
        theme: "colored"
    }
    switch (severity) {
        case "danger":
            toast.error(message, options);
            break;
        case "warn":
            toast.warn(message, options);
            break;
        case "success":
            toast.success(message, options);
            break;
        case "info" :
            toast.info(message, options);
            break;
        default:
            toast.info(message, options);
            break;
    }

}
export const priceViewer = (price) => {
    return `$${price.toFixed(2)}`;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
export const customDateFormatter = (date, option = 1, minCurrentDate = false) => {
    // Logger.log("DATE: ", date);

    let day = "";
    let month = "";
    let year = "";
    let halfYear = "";
    let fullDay = "";
    let luxonDate = "";
    const currentDate = new Date();
    const orignal = date;

    let dateSplit = null;
    // debugger;

    try {

        if (date instanceof Date) {

            if (date < currentDate && minCurrentDate) {
                date = getNewDate(false, "LL/dd/yyyy");
                luxonDate = getNewDate();
            } else {
                luxonDate = date = getNewDate(new Date(date));

                //luxonDate = getNewDate(dateSplit[2] + "-" + String(dateSplit[0]).padStart(2, '0') + "-" + String(dateSplit[1]).padStart(2, '0'));
            }


        } else {
            const checkDate = getNewDate(date, "LL/dd/yyyy");
            // Logger.log("Check Date ", checkDate);

            if (checkDate < currentDate && minCurrentDate) {
                // date = formatter.format(new Date());
                date = getNewDate(false, "LL/dd/yyyy");
                luxonDate = getNewDate();
            } else {
                // date = formatter.format(checkDate.toJSDate());
                luxonDate = getNewDate(date);
                date = checkDate;
            }
        }

        // Logger.log("DATE GETTER: ", date);

        dateSplit = date.toLocaleString().split("/");

        Logger.log("LUXON DATE: ", luxonDate);
        Logger.log("DATE SPLITTER: ", dateSplit);

        if (dateSplit.length < 3) {
            logger.log("RETURNING STRING DATE ,", date);
            return date;
        }

        year = dateSplit[2];
        month = parseInt(dateSplit[0]);
        day = dateSplit[1];
        halfYear = year.slice(-2);
        fullDay = String(day).padStart(2, '0');


        // Logger.log("DATE year: " , year);
        // Logger.log("DATE month: " , month);
        // Logger.log("DATE day: " , day);
        // Logger.log("DATE MONTHS: " , MONTHS);

        const monthGetter = _.find(MONTHS, p => p.number === month);

        switch (option) {
            case 1: // YYYY-mm-DD
                return {
                    formatted: year + "-" + month + "-" + day,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 2: // DD-mm-YYYY
                return {
                    formatted: day + "-" + month + "-" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 3: // DD MM, YYYY
                return {
                    formatted: day + " " + monthGetter.full + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 4: // DD M, YYYY
                return {
                    formatted: day + " " + monthGetter.half + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 5: // MM/DD
                return {
                    formatted: day + " " + monthGetter.half + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: monthGetter.string + "/" + day,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 6: // dd/mm/yy
                return {
                    formatted: day + "/" + monthGetter.half + "/" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + "/" + monthGetter.string + "/" + halfYear,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
            case 7: // mm/dd/yy
                return {
                    formatted: monthGetter.half + "/" + fullDay + "/" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: monthGetter.string + "/" + fullDay + "/" + halfYear,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }

            default: // YYYY-MM-DD
                return {
                    formatted: year + "-" + month + "-" + day,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate,
                    americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
                }
        }
    } catch (error) {
        Logger.log("ERROR in CUSTOM DATE FORMAT: ", error);
    }

}

export const customDateFormatterV2 = (date, option = 1, minCurrentDate = false) => {
    const formatDate = (date) =>
        new Intl.DateTimeFormat("en-US", {
            timeZone: "UTC",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).format(date);

    const getMonthDetails = (month) => MONTHS.find((m) => m.number === month);

    try {
        const currentDate = new Date();
        let parsedDate = date instanceof Date ? date : new Date(date);

        // Adjust date to current if needed
        if (minCurrentDate && parsedDate < currentDate) {
            parsedDate = currentDate;
        }

        const formattedDate = formatDate(parsedDate);
        const [month, day, year] = formattedDate.split("/").map(Number);
        const monthDetails = getMonthDetails(month);

        const halfYear = year.toString().slice(-2);
        const fullDay = String(day).padStart(2, "0");

        const result = {
            formatted: "",
            month: monthDetails,
            original: date,
            displayForm: `${day} ${monthDetails.full}, ${year}`,
            luxon: parsedDate.toISOString(),
            americanDisplay: `${String(month).padStart(2, "0")}/${fullDay}/${halfYear}`,
        };

        // Define formats based on the `option`
        switch (option) {
            case 1:
                result.formatted = `${year}-${month}-${day}`;
                break;
            case 2:
                result.formatted = `${day}-${month}-${year}`;
                break;
            case 3:
                result.formatted = `${day} ${monthDetails.full}, ${year}`;
                break;
            case 4:
                result.formatted = `${day} ${monthDetails.half}, ${year}`;
                break;
            case 5:
                result.formatted = `${monthDetails.string}/${day}`;
                result.displayForm = `${monthDetails.string}/${day}`;
                break;
            case 6:
                result.formatted = `${day}/${monthDetails.half}/${year}`;
                result.displayForm = `${day}/${monthDetails.string}/${halfYear}`;
                break;
            case 7:
                result.formatted = `${monthDetails.half}/${fullDay}/${year}`;
                result.displayForm = `${monthDetails.string}/${fullDay}/${halfYear}`;
                break;
            default:
                result.formatted = `${year}-${month}-${day}`;
        }

        return result;
    } catch (error) {
        console.error("Error in customDateFormatter:", error);
        return null;
    }
};

export const confirmSWAL = (title, confirmAction, confirmButtonText = "save", cancelButton = true) => {
    Swal.fire({
        title: title,
        showCancelButton: cancelButton,
        confirmButtonText: confirmButtonText,
    }).then((result) => {
        if (result.isConfirmed) {
            confirmAction();
        }
    })

}

export const orderTypeFinder = (arr, index) => {
    const finder = _.filter(arr, p => _.isEqual(parseInt(p), parseInt(index)))
    // Logger.log("FINDER: ", finder);

    return !!finder.length;

}
export const dateDifference = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    if (date1 > date2) {
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
    } else {
        return -Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
    }
}
export const dateDifferenceInWeeks = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTimeInMilliseconds = Math.abs(date2 - date1);
    Logger.log("=== DATE 1 : {{" + date1 + "}}: | DATE2 : {{" + date2 + "}}", diffTimeInMilliseconds);
    if (date1 > date2) {
        return Math.floor(diffTimeInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    } else {
        return -Math.floor(diffTimeInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    }
}
export const getNewDate = (date, format = undefined, checkByTodayDate = false) => {
    let dateToReturn = "";
    // const timeZone = "America/New_York";
    const timeZone = "UTC";
    try {
        if (date) {
            dateToReturn = DateTime.fromISO(date).setZone(timeZone);
            if (!dateToReturn.isValid) {
                dateToReturn = DateTime.fromHTTP(date).setZone(timeZone);
            }
        } else {
            dateToReturn = DateTime.now().setZone(timeZone);
        }

        if (checkByTodayDate && dateToReturn < DateTime.now()) {
            dateToReturn = DateTime.now().setZone(timeZone);
        }

        if (format) {
            return dateToReturn.toFormat(format);
        } else {
            return dateToReturn;
        }
    } catch (error) {
        Logger.error("DATE ERROR IN GET NEW DATE FORMAT: ", error);
        return date;
    }
}

export const getNewDateV2 = (date, format = undefined, checkByTodayDate = false) => {
    try {
        const timeZone = "America/New_York";
        let dateToReturn;

        // Parse the input date or default to the current date
        if (date) {
            dateToReturn = DateTime.fromISO(date, { zone: timeZone });
            if (!dateToReturn.isValid) {
                dateToReturn = DateTime.fromHTTP(date, { zone: timeZone });
            }
        } else {
            dateToReturn = DateTime.now().setZone(timeZone);
        }

        // Ensure the date is not in the past if `checkByTodayDate` is true
        if (checkByTodayDate && dateToReturn < DateTime.now().setZone(timeZone)) {
            dateToReturn = DateTime.now().setZone(timeZone);
        }

        // Return formatted date if a format is specified, otherwise return the DateTime object
        return format ? dateToReturn.toFormat(format) : dateToReturn;

    } catch (error) {
        Logger.error("DATE ERROR IN GET NEW DATE FORMAT:", error);
        return date || DateTime.now().setZone("America/New_York");
    }
};

export const getAmericanDateFormatter = (date, format) => {
    try {
        const parsedDate = new Date(date).toISOString();
        return getNewDate(parsedDate, format ? format : "MM/dd/y");
    } catch (error) {
        Logger.error("DATE ERROR IN AMERICAN DATE FORMAT: ", error);
        return date;
    }

}

export const getDateFormatForBackend = (date) => {
    const dateFormatted = date.getFullYear() + "-" + date.getMonth().toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " 00:00:00";
    Logger.log("DATE: ", dateFormatted)
    return dateFormatted;
}
export const compareTwoDates = (dateOne, dateTwo, comparison = "EQ") => {
    const dateOneParsed = getNewDate(new Date(dateOne).toISOString());
    const dateTwoParsed = getNewDate(new Date(dateTwo).toISOString());

    if (comparison === "EQ") {
        return dateOneParsed.startOf("day").ts === dateTwoParsed.startOf("day").ts;
    }

}
export const getTrackerDO = (previousTrack, newEvent, page, data, cb) => {
    const tracker = _.clone(previousTrack);

    tracker.push({
        event: newEvent,
        // data: data,
        page: page,
        timestamp: getNewDate()
    });

    // Logger.log("TRACKER: ", tracker);
    return tracker;

}
export const encryptionHeadersForProductApi = (text = 'authorized') => {
    // Generate random key and IV (Initialization Vector)
    const key = CryptoJS.lib.WordArray.random(32); // 256-bit key
    const iv = CryptoJS.lib.WordArray.random(16);  // 128-bit IV

    // Encrypt the text with AES-256-CBC
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });

    // Return headers with the encrypted data, key, and IV as hex
    return {
        "X-Authkey": key.toString(CryptoJS.enc.Hex),          // Key in hex
        "X-Candy": iv.toString(CryptoJS.enc.Hex),             // IV in hex
        "X-Authorization": encrypted.ciphertext.toString(CryptoJS.enc.Hex) // Encrypted data in hex
    };
};

export const excludeSplitFromId = (productId) => {
    Logger.log("Product Id: ", productId);
    if ((typeof productId === 'string' || productId instanceof String) && productId.includes("_split")) {
        return productId.split("_split")[0];
    }
    return productId;
}

export const arraysAreSimilar = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const getSizesGreaterThanZero = (qty) => {
    return Object.entries(qty)
        .filter(([size, quantity]) => size !== 'totalQuantity' && quantity > 0)
        .map(([size]) => size);
}

export const validateEmbroideryDetails = (cart) => {
    Logger.log("Cart to Validate Embroidery ===> ", cart);
    let hasEmbroideryAvailable = cart.some(item => item.embroideryAvailable);
    Logger.log("Has Embroidery Enable ===> ", hasEmbroideryAvailable);
    if (!hasEmbroideryAvailable) {
        return true;
    }

    return cart.every(item => {
        Logger.log("Has Embroidery Enable Item ===> ", item);
        if (item.embroideryAvailable && item.shouldAddEmbroidery) {
            return item.embroideryDetails.length > 0;
        }
        return true;
    });
}

export const checkExceedsMaxQty = (availability, cartItem) => {
    // Logger.log("==== Availability : ", availability);
    // Logger.log("==== Cart Item : ", cartItem);
    // Logger.log("==== Cart Item Quantity : ", cartItem.qty);

    if (cartItem) {
        for (const [key, value] of Object.entries(cartItem.qty)) {
            // Logger.log(`Key: ${key}, Value: ${value}`);
            const findSizeAvailability = _.find(availability, p => p.size.sizeName == key);
            if (findSizeAvailability && findSizeAvailability.hasOwnProperty("alternativeQtyAndDeliveryDate")
                && findSizeAvailability.alternativeQtyAndDeliveryDate) {
                // Logger.log("=== Availability In Loop : ", findSizeAvailability);
                if (value > findSizeAvailability.maxQtySelection) {
                    return true;
                }
            }
        }
    }


    return false;
}
export const checkExceedsMaxQtyInWholeCart = (cart) => {
    // Logger.log("==== Availability : ", availability);
    // Logger.log("==== Cart Item : ", cartItem);
    // Logger.log("==== Cart Item Quantity : ", cartItem.qty);
    // Logger.log("====> Cart : ", cart);
    let returnFlag = [];
    _.forEach(cart, (cartItem, key) => {

        const availability = cartItem.itemAvailability;

        if(!cartItem.hasOwnProperty("userDefinedDate") && !cartItem.userDefinedDate) {

            // Logger.log("====> Cart Item : ", cartItem);
            // Logger.log("====> Availability : ", availability);
            // Logger.log("====> Cart Item Quantity : ", cartItem.qty);
            for (const [key, value] of Object.entries(cartItem.qty)) {
                // Logger.log(`Key: ${key}, Value: ${value}`);
                const findSizeAvailability = _.find(availability, p => p.size.sizeName == key);

                // Logger.log("====> Find Availability : ", findSizeAvailability);
                if (findSizeAvailability && findSizeAvailability.hasOwnProperty("alternativeQtyAndDeliveryDate")
                    && findSizeAvailability.alternativeQtyAndDeliveryDate) {
                    // Logger.log("=== Availability In Loop : ", findSizeAvailability);
                    if (value > findSizeAvailability.maxQtySelection) {
                        // Logger.log("====> Item Exceeded : ", cartItem);

                        if (!areDatesEqual(new Date(cartItem.changeableDeliveryDate.original),
                            new Date(findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate.original))) {
                            returnFlag.push({
                                item: cartItem,
                                date: findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate
                            });
                        }
                    } else if (value) {
                        if (areDatesEqual(new Date(cartItem.changeableDeliveryDate.original),
                            new Date(findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate.original))) {
                            returnFlag.push({
                                item: cartItem,
                                date: findSizeAvailability.alternativeQtyAndDeliveryDate.originalDeliveryDate
                            });
                        }
                    }
                }
            }
        }
    });
    return returnFlag;
}

export const areDatesEqual = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export const getAvailableSizes = (sizes, items)  => {
    const availableSizes = new Set();

    items.forEach(item => {
        for (const [size, quantity] of Object.entries(item.qty)) {
            if (sizes.includes(size) && quantity > 0) {
                availableSizes.add(size);
            }
        }
    });

    return sizes.filter(size => availableSizes.has(size));
}
export const getAllQtyFromPlacedOrder = (orders) =>  {
    Logger.log("PARSING THE QTY IN SORTING ORDER");
    const sizesWithQty = new Set();

    orders.forEach(order => {
        order.orderDetails.forEach(detail => {
            const parsedQty = JSON.parse(detail.qty);

            // Filter out sizes with qty > 0, ignoring 'totalQuantity'
            const sizes = Object.entries(parsedQty)
                .filter(([key, value]) => key !== 'totalQuantity' && value > 0)
                .map(([key]) => key);

            sizes.forEach(size => sizesWithQty.add(size));
        });
    });

    // Convert the Set back to an array and add "totalQuantity"
    const uniqueSizesWithQty = Array.from(sizesWithQty);
    uniqueSizesWithQty.push("totalQuantity");

    // Custom sort order array
    const CUSTOM_ORDER = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "ONE SIZE", "KIDS 9-3", "WOMEN 4-10", "totalQuantity"];

    // Sort sizes based on their index in CUSTOM_ORDER
    uniqueSizesWithQty.sort((a, b) => {
        const indexA = CUSTOM_ORDER.indexOf(a.toUpperCase());
        const indexB = CUSTOM_ORDER.indexOf(b.toUpperCase());

        // If either size is not found, place it at the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });

    return uniqueSizesWithQty;
}

export const getAllQtyFromConfirmationOrder = (orders) =>  {
    Logger.log("PARSING THE QTY IN SORTING ORDER")
    const sizesWithQty = new Set(); // Use a Set to store sizes uniquely

    orders.forEach(order => {
        order.items.items.forEach(detail => {
            const parsedQty = detail.qty; // Parse the qty string into an object

            // Filter out sizes with qty > 0, ignoring 'totalQuantity'
            const sizes = Object.entries(parsedQty)
                .filter(([key, value]) => key !== 'totalQuantity' && value > 0)
                .map(([key]) => key); // Extract only the size names

            sizes.forEach(size => sizesWithQty.add(size)); // Add sizes to the Set
        });
    });

    // Convert the Set back to an array and add "totalQuantity"
    const uniqueSizesWithQty = Array.from(sizesWithQty);
    uniqueSizesWithQty.push("totalQuantity");

    // Custom sort order array
    const CUSTOM_ORDER = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "ONE SIZE", "KIDS 9-3", "WOMEN 4-10", "totalQuantity"];

    // Sort sizes based on their index in CUSTOM_ORDER
    uniqueSizesWithQty.sort((a, b) => {
        const indexA = CUSTOM_ORDER.indexOf(a.toUpperCase());
        const indexB = CUSTOM_ORDER.indexOf(b.toUpperCase());

        // If either size is not found, place it at the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });

    return uniqueSizesWithQty;
}


export const filterEmbroideryLocations = (fields)  => {
    // Split the EMB_LOC field by comma to get individual IDs
    // Logger.log("==== FIELDS: " , fields);
    if(fields && fields.hasOwnProperty(window.config.EMBROIDERY_LOCATION_FIELD_KEY) && fields[window.config.EMBROIDERY_LOCATION_FIELD_KEY]) {
        const selectedIds = fields[window.config.EMBROIDERY_LOCATION_FIELD_KEY].split(',');
        // Logger.log("==== SELECTED ID: " , selectedIds);
        if(window.config.EMBROIDERY_EXCLUDE_LOCATION_FIELDS) {
            return window.config.EMBROIDERY_LOCATIONS.filter(location =>
                selectedIds.includes(location.id)
            );
        } else {
            return window.config.EMBROIDERY_LOCATIONS.filter(location =>
                !selectedIds.includes(location.id)
            );
        }
    }

    return window.config.EMBROIDERY_LOCATIONS
}

export const areAllQuantitiesZero = (obj) => {
    // Extract the 'qty' object from the given object
    const quantities = obj.qty;
    // Check if every value in the 'qty' object is 0
    return Object.entries(quantities)
        .filter(([key]) => key !== 'totalQuantity') // Ignore 'totalQuantity'
        .every(([, qty]) => qty === 0);
}

export const getLatestDeliveryDate = (qty, sizeData)  => {
    let latestDeliveryDate = null;
    // Iterate over each size in the qty object
    Object.keys(qty).forEach((sizeName) => {
        // Logger.log("-=-= getLatestDeliveryDate | Size: " , sizeName);
        const quantity = qty[sizeName];

        // Skip sizes with zero quantity
        if (quantity <= 0) return;

        if(sizeName === "totalQuantity") return;

        // Find the corresponding size object in sizeData
        const sizeObj = sizeData.find((item) => {
            const overlay = window.config.OVERLAY_STYLES.find(
                (style) =>
                    style.originalStyle === item.sizeName ||
                    style.alternativeStyle === item.sizeName
            );

            return overlay !== undefined;
        });


        Logger.log("-=-= getLatestDeliveryDate | Size Object: " , sizeObj);

        if (!sizeObj) return; // Skip if size data not found

        // Apply the rules to determine max quantity and delivery date for this size
        const { maxQtySelection, deliveryDate, currentSizeStatus, alternativeQtyAndDeliveryDate } = sizeObj;
        const { atsstock, wip } = currentSizeStatus;

        let sizeDeliveryDate = deliveryDate;

        Logger.log("-=-= getLatestDeliveryDate | Delivery Date: " , deliveryDate);

        if(quantity > maxQtySelection && alternativeQtyAndDeliveryDate) {
            sizeDeliveryDate = alternativeQtyAndDeliveryDate.wipDate;
        } else {
            sizeDeliveryDate = deliveryDate;
        }


        // Update the latest delivery date if the current one is later
        if (sizeDeliveryDate && (!latestDeliveryDate || new Date(sizeDeliveryDate) > new Date(latestDeliveryDate))) {
            latestDeliveryDate = sizeDeliveryDate;
        }


        Logger.log("-=-= getLatestDeliveryDate | Latest Date: " , latestDeliveryDate);
    });

    Logger.log("--==--==--== Latest Delivery Date in helper: " , latestDeliveryDate);

    return latestDeliveryDate ? customDateFormatterV2(latestDeliveryDate.original) : customDateFormatterV2(getNewDate());
}

export const calculateLatestDeliveryDatesForItems = (cartDetailRevamped) => {
    return cartDetailRevamped
        .map((item) => {
            const { qty, itemAvailability } = item;

            let latestDeliveryDate = null;

            // Iterate over each size and calculate the latest delivery date
            Object.keys(qty).forEach((sizeName) => {
                const quantity = qty[sizeName];

                // Skip sizes with zero quantity
                if (quantity <= 0) return;

                // Find the size data in itemAvailability

                const sizeData = itemAvailability.find((item) => {
                    const overlay = window.config.OVERLAY_STYLES.find(
                        (style) =>
                            style.originalStyle === item.sizeName ||
                            style.alternativeStyle === item.sizeName
                    );

                    return overlay !== undefined;
                });



                // const sizeData = itemAvailability.find(
                //     (availability) => availability.sizeName === sizeName
                // );

                if (!sizeData) return; // Skip if size data is not found

                const { maxQtySelection, deliveryDate, alternativeQtyAndDeliveryDate } = sizeData;
                const { wipDate } = alternativeQtyAndDeliveryDate || {};

                // Determine delivery date based on rules
                const sizeDeliveryDate =
                    quantity > maxQtySelection && wipDate
                        ? wipDate?.original
                        : deliveryDate?.original;

                // Update the latest delivery date if necessary
                if (
                    sizeDeliveryDate &&
                    (!latestDeliveryDate || new Date(sizeDeliveryDate) > new Date(latestDeliveryDate))
                ) {
                    latestDeliveryDate = sizeDeliveryDate;
                }
            });

            Logger.log("--==--==--== Latest Delivery Date in helper Array: ", latestDeliveryDate);

            // If a latest delivery date exists, return the formatted date
            return latestDeliveryDate ? customDateFormatterV2(latestDeliveryDate) : null;
        })
        .filter((date) => date !== null); // Filter out null values
};


export const getMaxQtyAndDeliveryDateFromItemAvailability = (sizes) => {
    return sizes.map(size => {
        const { currentSizeStatus, deliveryDate } = size;
        const { atsstock, wip } = currentSizeStatus;

        let maxQty = 0;
        let finalDeliveryDate = null;

        if (atsstock > 0 && (!wip || wip.length === 0)) {
            // Rule 1: atsstock and no wip
            maxQty = atsstock;
            finalDeliveryDate = deliveryDate?.formatted || null;
        } else if (atsstock > 0 && wip && wip.length > 0) {
            // Rule 2: atsstock and wip
            maxQty = atsstock + wip[0].qty;
            finalDeliveryDate = wip[0].date;
        } else if ((!atsstock || atsstock <= 0) && wip && wip.length > 0) {
            // Rule 3: No atsstock but wip exists
            maxQty = wip[0].qty;
            finalDeliveryDate = wip[0].date;
        } else {
            // Rule 4: No atsstock and no wip
            maxQty = 0;
            finalDeliveryDate = null;
        }

        return {
            sizeNumber: size.sizeNumber,
            sizeName: size.sizeName,
            maxQty,
            deliveryDate: finalDeliveryDate,
        };
    });
}
export const getMaxQtyAndDeliveryDate = (sizeKey, availabilityStatus) => {
    if (!availabilityStatus || !availabilityStatus[sizeKey]) {
        return { maxQty: 0, deliveryDate: null, message: "Invalid size or availability status." };
    }

    const sizeAvailability = availabilityStatus[sizeKey];
    const { atsstock, wip } = sizeAvailability;

    // Check for no atsstock and no wip
    if (atsstock === 0 && (!wip || wip.length === 0 || wip[0].qty === 0)) {
        return { maxQty: 0, deliveryDate: null, message: "Can't order, no stock or work in progress." };
    }

    // Check if atsstock is present and wip is not present
    if (atsstock > 0 && (!wip || wip.length === 0 || wip[0].qty === 0)) {
        return { maxQty: atsstock, deliveryDate: null, message: "Max quantity available from stock." };
    }

    // Check if atsstock is present and wip is also present
    if (atsstock > 0 && wip && wip.length > 0 && wip[0].qty > 0) {
        return {
            maxQty: atsstock + wip[0].qty,
            deliveryDate: wip[0].date,
            message: `Max quantity from stock and first WIP on ${wip[0].date}.`,
        };
    }

    // Check if atsstock is not present but wip is present
    if (atsstock === 0 && wip && wip.length > 0 && wip[0].qty > 0) {
        return {
            maxQty: wip[0].qty,
            deliveryDate: wip[0].date,
            message: `Max quantity from first WIP on ${wip[0].date}.`,
        };
    }

    // Default case (shouldn't be reached)
    return { maxQty: 0, deliveryDate: null, message: "Unexpected condition." };
}
